import React from 'react';
import { withLayout } from '../containers/Layout';
const PageIndex = () => {
  return (
    <>
      <p>Olá!, lorem ipsum dolor sit amet</p>
    </>
  );
};

export default () => withLayout({ title: 'Home' }, <PageIndex />);
